
<div class="sidebar">

    <div class="sidebar-outer">

        <div class="sidebar-inner">
        
            <div class="sidebar-logo-wrap" routerLink="/">
                <div class="sidebar-logo-image"></div>
            </div>

            <div class="sidebar-menu-items-wrap">

                <div class="sidebar-menu-items-wrap-top">

                    <!-- DASHBOARD -->
                    <div class="sidebar-menu-wrap" [ngClass]="{'sidebar-menu-active': activeMenu == 'dashboard'}" (click)="navigate('dashboard')">
                        <i class="fas fa-gauge sidebar-menu-icon"></i>
                        <div class="sidebar-menu-name-wrap">Dashboard</div>
                    </div>
                    
                    <!-- PRODUCTS -->
                    <div *ngIf="( userSalesManager || userCustomerAdmin || userSystemAdmin || userSalesStaff )" class="sidebar-menu-wrap" [ngClass]="{'sidebar-menu-active': activeMenu.includes('products/') }" (click)="navigate('products/list')">
                        <i class="fa-sharp fa-solid fa-hand-holding-box sidebar-menu-icon"></i>
                        <div class="sidebar-menu-name-wrap">Produkter</div>
                    </div>
                    
                    <!-- PRODUCTS LIST -->
                    <div *ngIf="( userCustomerAdmin || userSystemAdmin ) && activeMenu.includes('products')" class="submenu">
                        <div class="submenu-border"></div>
                        <div class="submenu-border-active" [ngStyle]="{'top.px': topPosition}"></div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('products/list') }" (click)="navigate('products/list')">Produktlista</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('products/categories') }" (click)="navigate('products/categories')">Kategorier</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('products/prices') }" (click)="navigate('products/prices')">Priser</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('products/currencies') }" (click)="navigate('products/currencies')">Valutor</div>
                        <!-- <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('products/create') }" (click)="navigate('products/create')">Ny produkt</div> -->
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('products/article-groups') }" (click)="navigate('products/article-groups')">Artikelgrupper</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('products/attributes') }" (click)="navigate('products/attributes')">Attribut</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('products/discount-groups') }" (click)="navigate('products/discount-groups')">Rabatter</div>
                    </div>
                    
                    <!-- ORDERS -->
                    <div class="sidebar-menu-wrap" [ngClass]="{'sidebar-menu-active': activeMenu.includes('orders/') }" (click)="navigate('orders/list')">
                        <i class="fa-sharp fa-solid fa-dolly sidebar-menu-icon"></i>
                        <div class="sidebar-menu-name-wrap">Order</div>
                    </div>
                    
                    <!-- ORDERS SUBMENU -->
                    <div class="submenu" *ngIf="activeMenu.includes('orders')">
                        <div class="submenu-border"></div>
                        <div class="submenu-border-active" [ngStyle]="{'top.px': topPosition}"></div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('orders/list') }" (click)="navigate('orders/list')">Orderlista</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('orders/history') }" (click)="navigate('orders/history')">Försäljningshistorik</div>
                    </div>
                    
                    <!-- SHIPPING -->
                    <div class="sidebar-menu-wrap" *ngIf="userCustomerAdmin || userSystemAdmin" [ngClass]="{'sidebar-menu-active': activeMenu.includes('shipping/') }" (click)="navigate('shipping/options/list')">
                        <i class="fa-sharp fa-solid fa-cart-flatbed-boxes sidebar-menu-icon"></i>
                        <div class="sidebar-menu-name-wrap">Leverans</div>
                    </div>
                    
                    <!-- SHIPPING SUBMENU -->
                    <div class="submenu" *ngIf="activeMenu.includes('shipping') && ( userCustomerAdmin || userSystemAdmin )">
                        <div class="submenu-border"></div>
                        <div class="submenu-border-active" [ngStyle]="{'top.px': topPosition}"></div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('shipping/options/list') }" (click)="navigate('shipping/options/list')">Fraktalternativ</div>
                    </div>
                    
                    <!-- INVOICES -->
                        <!-- <div class="sidebar-menu-wrap" [ngClass]="{'sidebar-menu-active': activeMenu == 'invoices'}" (click)="navigate('invoices')">
                            <i class="fa-sharp fa-solid fa-file-invoice-dollar sidebar-menu-icon"></i>
                            <div class="sidebar-menu-name-wrap">Fakturor</div>
                        </div> -->

                    <!-- CUSTOMERS -->
                    <div class="sidebar-menu-wrap" [ngClass]="{'sidebar-menu-active': activeMenu == 'customers'}" (click)="navigate('customers')">
                        <i class="fa-sharp fa-solid fa-building sidebar-menu-icon"></i>
                        <div class="sidebar-menu-name-wrap">Kunder</div>
                    </div>

                    <!-- CUSTOMERS SUBMENU -->
                    <div class="submenu" *ngIf="activeMenu.includes('customers') && ( userCustomerAdmin || userSystemAdmin || userSalesManager )">
                        <div class="submenu-border"></div>
                        <div class="submenu-border-active" [ngStyle]="{'top.px': topPosition}"></div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('customers/list') }" (click)="navigate('customers/list')">Kundlista</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('customers/management') }" (click)="navigate('customers/management/assign-users')">Batch</div>
                    </div>

                    <!-- CUSTOMERS SUBMENU -->
                    <div *ngIf="activeMenu.includes('customers') && false" class="submenu">
                        <div class="submenu-border"></div>
                        <div class="submenu-border-active" [ngStyle]="{'top.px': topPosition}"></div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('customers/list') }" (click)="navigate('customers/list')">Kundlista</div>
                    </div>

                    <div *ngIf="( userSalesManager || userCustomerAdmin || userSystemAdmin )" class="sidebar-menu-wrap" [ngClass]="{'sidebar-menu-active': activeMenu == 'users'}" (click)="navigate('users')">
                        <i class="fa-sharp fa-solid fa-user sidebar-menu-icon"></i>
                        <div class="sidebar-menu-name-wrap">Användare</div>
                    </div>

                    <div *ngIf="( userCustomerAdmin || userSystemAdmin )" class="sidebar-menu-wrap" [ngClass]="{'sidebar-menu-active': activeMenu == 'media'}" (click)="navigate('media')">
                        <i class="fa-sharp fa-solid fa-image sidebar-menu-icon"></i>
                        <div class="sidebar-menu-name-wrap">Media</div>
                    </div>
                    
                    <div *ngIf="userSystemAdmin" class="sidebar-menu-wrap" [ngClass]="{'sidebar-menu-active': activeMenu.includes('system')}" (click)="navigate('system/settings')">
                        <i class="fa-sharp fa-solid fa-gear sidebar-menu-icon"></i>
                        <div class="sidebar-menu-name-wrap">System</div>
                    </div>

                    <div *ngIf="userSystemAdmin && activeMenu.includes('system/')" class="submenu">
                        <div class="submenu-border"></div>
                        <div class="submenu-border-active" [ngStyle]="{'top.px': topPosition}"></div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('settings') }" (click)="navigate('system/settings')">Inställningar</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('executor') }" (click)="navigate('system/executor')">Executor</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('cache') }" (click)="navigate('system/cache')">Cache</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('sync') }" (click)="navigate('system/sync')">Sync</div>
                        <div class="sublink" [ngClass]="{'sublink-active': activeMenu.includes('logs') }" (click)="navigate('system/logs')">Logs</div>
                    </div>

                </div>
                
                <div class="sidebar-menu-items-wrap-bottom">

                    <div class="sidebar-menu-wrap" [ngClass]="{'sidebar-menu-active': activeMenu == 'profile'}" (click)="navigate('profile')">
                        
                        <div class="sidebar-profile-link-wrap">

                            <span *ngIf="userIsCrowned === true">
                                <div class="lottie-animation mt-10">
                                    <ng-lottie
                                        [options]="aniconCrown"
                                        width="30px"
                                        height="30px">
                                    </ng-lottie>
                                </div>
                            </span>

                            <div *ngIf="userIsCrowned === false" class="current-user-circle" [style.background-color]="currentUser?.profileColor || '#34435b'">
                                <span class="uppertitle" *ngIf="currentUser?.firstName">{{ currentUser?.firstName?.charAt(0) }}</span>
                                <span class="uppertitle" *ngIf="currentUser?.lastName">{{ currentUser?.lastName?.charAt(0) }}</span>
                            </div>

                            <div *ngIf="currentUser" class="sidebar-menu-name-wrap">{{currentUser.firstName}} {{currentUser.lastName}}</div>

                        </div>
                    </div>
                    
                </div>

            </div>
    
        </div>

    </div>

</div>